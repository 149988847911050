@charset "UTF-8";
/*
 * My Awesome Stylesheet
 *
 * Author: Cedric Chetty
 * Version: 1.0.0
 * Last updated: 9.7.2023
 *!
 * WARNING: Editing this CSS File
 *
 * This CSS file contains the layout and styling of the website. Modifying this file without proper understanding of CSS may have unintended consequences and potentially affect the entire website.
 * Please exercise caution when making changes to this file. It is recommended to have a good understanding of CSS and the impact of your modifications on the overall website layout and functionality.
 * If you are unsure about making changes to this file, please consult with a developer or someone with expertise in CSS to avoid any detrimental effects.
 * Any modifications made to this file should be thoroughly tested to ensure they do not introduce issues or break the website's appearance or functionality.
 * By continuing to edit this CSS file, you acknowledge the potential risks associated with making changes without proper CSS knowledge.
 */
@font-face {
  font-family: "Adelle-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Adelle-Bold.woff2") format("woff2"), url("../fonts/Adelle-Bold.woff") format("woff");
}
@font-face {
  font-family: "Adelle-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Adelle-Regular.woff2") format("woff2"), url("../fonts/Adelle-Regular.woff") format("woff");
}
@font-face {
  font-family: "SourceSansPro-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/SourceSansPro-Regular.woff2") format("woff2"), url("../fonts/SourceSansPro-Regular.woff") format("woff");
}
@font-face {
  font-family: "SourceSansPro-Semibold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/SourceSansPro-Semibold.woff2") format("woff2"), url("../fonts/SourceSansPro-Semibold.woff") format("woff");
}
:root {
  --c-teal: #287C9B;
  --c-turquoise: #29C2B2;
  --c-apricot: #FECA7D;
  --c-cream: #FEF9EF;
  --c-grey: #6A6A6A;
  --navbar-height: 15vh;
  --font-Adelle-Bold: Adelle-Bold, Arial, Helvetica, sans-serif;
  --font-Adelle-Regular: Adelle-Regular, Arial, Helvetica, sans-serif;
  --font-SourceSansPro-Regular: SourceSansPro-Regular, Arial, Helvetica, sans-serif;
  --font-SourceSansPro-Semibold: SourceSansPro-Semibold, Arial, Helvetica, sans-serif;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  font-size: clamp(1rem, 0.3571428571rem + 1.7857142857vw, 1.5rem);
  scroll-behavior: smooth;
}

body {
  margin: 0;
  display: grid;
  grid-template-rows: minmax(min-content, 15vh) repeat(2, auto);
}

/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a,
button {
  cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol,
ul,
menu {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-inline-size: 100%;
  max-block-size: 100%;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input,
textarea {
  user-select: auto;
  -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}

/* preformatted text - use only for this feature */
:where(pre) {
  all: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}

/* remove default dot (•) sign */
::marker {
  content: initial;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable="false"])) {
  overflow-wrap: break-word;
  line-break: after-white-space;
  user-select: auto;
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
  -webkit-user-drag: element;
}

/* Revert Modal native behavior */
:where(dialog:modal) {
  all: revert;
}

.title {
  font-size: 2.375rem;
  font-family: var(--font-Adelle-Bold);
}
@media screen and (max-width: 767.98px) {
  .title span {
    display: block;
  }
}
.title small {
  font-size: 0.8985507246em;
  font-family: var(--font-Adelle-Regular);
}

.heading--primary {
  font-size: 1.6583333333rem;
  color: var(--c-teal);
  font-family: var(--font-Adelle-Bold);
}
.heading--primary > small {
  font-size: 0.6808510638em;
  white-space: nowrap;
  font-family: var(--font-Adelle-Regular);
}
.heading--primary + .paragraph {
  margin-top: 1.0416666667rem;
}
.heading--secondary {
  font-size: 1.375rem;
  color: var(--c-turquoise);
  font-family: var(--font-Adelle-Regular);
}
.heading--secondary + .subheading--secondary {
  margin-block-start: 1rem;
}
.heading--secondary + .form__image {
  margin-block-end: 1rem;
}
.heading--secondary + .paragraph {
  margin-top: 1em;
}

.subheading--primary {
  font-size: 1.3333333333rem;
  color: var(--c-apricot);
  font-family: var(--font-Adelle-Bold);
}
.subheading--secondary {
  font-size: 1.2083333333rem;
  color: var(--c-apricot);
  font-family: var(--font-Adelle-Regular);
  text-decoration: underline;
}
.subheading--secondary ~ .c-price {
  font-size: 1rem;
}
.subheading--secondary + .form {
  margin-top: 1rem;
}

.lead span:first-child {
  color: var(--c-turquoise);
  font-size: 1.1666666667rem;
  font-family: var(--font-SourceSansPro-Semibold);
}
.lead span:last-child {
  color: var(--c-grey);
  font-size: 0.8333333333rem;
  font-family: var(--font-SourceSansPro-Regular);
}

.paragraph {
  font-size: max(16px, 0.775rem);
  line-height: max(16px, 1.4em);
  font-family: var(--font-SourceSansPro-Regular);
  color: #8E8E8E;
}
.paragraph + .subheading--primary {
  margin-top: 1.5833333333rem;
}
.paragraph > p + p {
  margin-top: 1em;
}

.c-price {
  font-size: max(16.8px, 0.7916666667rem);
  color: var(--c-teal);
  font-family: var(--font-SourceSansPro-Semibold);
}
.c-price > small {
  font-size: 0.8571428571em;
  font-family: var(--font-SourceSansPro-Regular);
}
.c-price + .button {
  margin-top: 1.5833333333rem;
}
.c-price + .subheading--secondary {
  margin-block-start: 1rem;
}

section.container > header {
  text-align: center;
}

#visite_guides {
  background-color: var(--c-cream);
}
#visite_guides .heading--primary {
  margin-bottom: 0.875rem;
}
#visite_guides .lead {
  display: flex;
  flex-direction: column;
  row-gap: 0.5833333333em;
}

.form input:not([type=submit]),
.form textarea {
  background-color: var(--c-cream);
  padding-inline: 0.6em;
  padding-block: 0.5em;
  font-family: var(--font-SourceSansPro-Regular);
  width: 100%;
}
.form ::placeholder {
  color: #8E8E8E;
}

#welcomeMessage .heading--secondary {
  font-family: var(--font-SourceSansPro-Semibold);
  text-align: center;
}
#welcomeMessage .paragraph > p {
  color: var(--c-turquoise);
  text-align: center;
  max-inline-size: 45ch;
}

.footer {
  display: flex;
  padding: 1.5rem;
}
.footer p {
  align-self: center;
  color: #fff;
  font-size: 0.675rem;
  font-family: var(--font-SourceSansPro-Regular);
}

.button {
  display: inline-block;
  text-transform: uppercase;
  width: fit-content;
  font-family: var(--font-Adelle-Regular);
  cursor: pointer;
}
.button--primary {
  font-size: max(16px, 0.775rem);
  color: var(--c-teal);
  background-color: var(--c-apricot);
  padding: 0.7083333333em 1.2083333333em;
}
.button--secondary {
  border-radius: 0.5em;
  font-size: 0.6666666667rem;
  color: var(--c-teal);
  background-color: #fff;
  padding: 0.5em 0.4583333333em;
}
.button--close {
  background-color: #8E8E8E;
  color: #fff;
  padding: 0.3em 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.button--close + .heading--secondary {
  padding-top: 1rem;
}

input.button--primary {
  background-color: var(--c-turquoise);
  color: #fff;
  font-family: var(--font-SourceSansPro-Semibold);
}

.controls > a {
  cursor: pointer;
}

.menu {
  display: none;
}
.menu ul {
  display: flex;
  flex-wrap: nowrap;
}

.navbar {
  padding: 1rem 2rem;
  background: var(--c-cream);
  display: flex;
  align-items: center;
}
.navbar__menu, .navbar__container {
  align-items: center;
}

.navbar__container {
  width: 100%;
  display: grid;
}
@media screen and (max-width: 1024px) {
  .navbar__container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  .navbar__container {
    grid-template-columns: repeat(3, 1fr);
  }
}
.navbar__brand {
  width: clamp(120px, 68.5714285714px + 8.9285714286vw, 160px);
  display: inline-flex;
}
.navbar__brand > img {
  min-inline-size: 1em;
  min-block-size: 0.33em;
}
.navbar__menu {
  color: var(--c-teal);
  display: flex;
}
.navbar__menu:first-of-type {
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  .navbar__menu:first-of-type {
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    min-width: 290px;
    background-color: var(--c-cream);
    -webkit-transition: right 600ms ease;
    -moz-transition: right 600ms ease;
    -ms-transition: right 600ms ease;
    -o-transition: right 600ms ease;
    transition: right 600ms ease;
  }
  .navbar__menu:first-of-type.active {
    right: 0;
  }
  .navbar__menu:first-of-type li {
    -webkit-transition: color 600ms ease-in-out;
    -moz-transition: color 600ms ease-in-out;
    -ms-transition: color 600ms ease-in-out;
    -o-transition: color 600ms ease-in-out;
    transition: color 600ms ease-in-out;
  }
  .navbar__menu:first-of-type li:hover {
    color: var(--c-turquoise);
  }
  .navbar__menu:first-of-type li + li {
    margin-block-start: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .navbar__menu:first-of-type li span {
    display: block;
  }
  .navbar__menu:first-of-type li + li {
    margin-inline-start: 1.25rem;
  }
}
.navbar__menu:last-of-type {
  color: var(--c-turquoise);
  justify-content: end;
  gap: 0.525rem;
}
@media screen and (max-width: 1024px) {
  .navbar__menu:last-of-type {
    position: absolute;
    right: 0;
    padding-inline-end: 2rem;
  }
}
.navbar__menu li {
  color: inherit;
  font-family: var(--font-SourceSansPro-Regular);
  text-align: center;
  min-inline-size: max-content;
  font-size: max(19px, 0.8333333333rem);
}
.navbar__language {
  margin-inline-start: 1rem;
  border-block-end: 1pt solid;
}

.navbar__toggle {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem;
  display: grid !important;
  place-content: center;
}
@media screen and (min-width: 1024px) {
  .navbar__toggle {
    display: none !important;
  }
}

.footer {
  min-height: 3rem;
  margin-block-start: 2.5rem;
  background-color: var(--c-teal);
}

.footer p {
  color: #fff;
  font-size: 0.675rem;
}

.container {
  padding-inline: 1.5rem;
  padding-top: 2.6666666667rem;
}
.container--fluid {
  width: 100%;
}
.container > header {
  margin-bottom: 2.2083333333rem;
}
@media screen and (min-width: 768px) and (max-width: 1023.98px) {
  .container {
    padding-inline: 3.25rem;
  }
}
@media screen and (min-width: 1024px) {
  .container {
    padding-inline: 3.25rem;
  }
}

#header-carousel {
  display: grid;
  flex-direction: column;
  place-items: center;
  color: #fff;
  text-align: center;
  height: calc(100vh - var(--navbar-height));
}
#header-carousel > .slider img, #header-carousel > * {
  grid-column: 1/2;
  grid-row: 1/2;
}

#header-carousel .title {
  height: fit-content;
}
#header-carousel > .slider {
  position: relative;
  display: grid;
  z-index: -1;
  height: 100%;
  overflow: hidden;
}
#header-carousel > .slider img {
  opacity: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: fade 10s infinite;
}
#header-carousel > .slider img:nth-child(2) {
  animation-delay: 3.3333333333s;
}
#header-carousel > .slider img:nth-child(3) {
  animation-delay: 6.6666666667s;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  53% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.grid-layout {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 768px) and (max-width: 1023.98px) {
  .grid-layout {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-layout > :last-child {
    grid-column: 1/3;
  }
}
@media screen and (min-width: 1024px) {
  .grid-layout {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-layout > :last-child {
    grid-column: 1/4;
    display: grid;
    grid-template-columns: 4.1666666667rem repeat(3, auto);
    grid-auto-rows: repeat(5, auto);
    margin-bottom: 2.6666666667rem;
  }
  .grid-layout > :last-child .grid-item {
    grid-template-columns: 1fr;
  }
  .grid-layout > :last-child .grid-item__images {
    grid-column: 1/5;
    grid-row: 1/6;
    height: max(400px, 70vh);
    overflow: hidden;
  }
  .grid-layout > :last-child .grid-item__body {
    grid-column: 2/3;
    grid-row: 3/4;
    z-index: 2;
    background-color: #013c4fc7;
  }
}
.grid-item {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  isolation: isolate;
}
@media screen and (min-width: 768px) {
  .grid-item {
    grid-template-rows: repeat(2, auto) minmax(7.0833333333rem, 100%);
  }
}
.grid-item__body, .grid-item__images, .grid-item__header {
  grid-column: 1/2;
}

.grid-item > .grid-item__header {
  padding: 0 0.75rem;
  background: linear-gradient(0deg, black -100%, rgba(0, 0, 0, 0) 100%);
}
.grid-item__header {
  z-index: 2;
  grid-row: 2/3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  color: #fff;
}
.grid-item__header h3 {
  font-size: 1.25rem;
  font-family: var(--font-Adelle-Bold);
}
.grid-item__header .controls {
  margin-bottom: 0.875rem;
}
.grid-item__images {
  z-index: 1;
  grid-row: 1/3;
  display: grid;
}
.grid-item__images img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1.5/1;
  object-fit: cover;
  grid-column: 1/2;
  grid-row: 1/2;
}
.grid-item__body {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  background-color: var(--c-teal);
  padding: 0.6666666667rem 0.75rem;
}
.grid-item__body p {
  font-size: max(16px, 0.75rem);
  font-family: var(--font-SourceSansPro-Regular);
  color: #fff;
  flex-grow: 2;
}
.grid-item__body .button {
  align-self: flex-end;
}

#transfere_aeroport_lile_maurice {
  margin-bottom: 2.6666666667rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media screen and (min-width: 1024px) {
  #transfere_aeroport_lile_maurice {
    flex-direction: row;
  }
}
#transfere_aeroport_lile_maurice .subheading--primary {
  text-decoration: underline;
}
#transfere_aeroport_lile_maurice .column:first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (min-width: 1024px) {
  #transfere_aeroport_lile_maurice .column:first-child {
    width: 70%;
  }
}
@media screen and (min-width: 1024px) {
  #transfere_aeroport_lile_maurice .column:last-child .heading--primary span {
    display: block;
  }
  #transfere_aeroport_lile_maurice .column:last-child .paragraph {
    max-width: 50ch;
  }
}

#contacts .flex-layout {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  #contacts .flex-layout {
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  #contacts .flex-layout__item {
    width: 50%;
  }
}
#contacts .flex-layout__item .lead, #contacts .flex-layout__item h3 {
  color: var(--c-turquoise);
}
#contacts .flex-layout__item h3 {
  font-size: 1.25rem;
  font-family: var(--font-Adelle-Bold);
}
#contacts .flex-layout__item .lead {
  font-size: 1.1666666667rem;
  font-family: var(--font-SourceSansPro-Regular);
}
#contacts .flex-layout__item > fieldset {
  width: 100%;
  margin: auto;
  border: 2pt solid var(--c-teal);
  padding-top: 1.875rem;
  padding-right: 1.6666666667rem;
  padding-bottom: 1.6666666667rem;
  padding-left: 1.6666666667rem;
}
@media screen and (min-width: 1024px) {
  #contacts .flex-layout__item > fieldset {
    width: 80%;
  }
}
#contacts .flex-layout__item > fieldset legend {
  padding: 0.6rem;
}
#contacts .flex-layout__item > fieldset legend img {
  display: inline-block;
  width: 4rem;
}
#contacts .contact__list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#contacts .contact__list .phone::before {
  background-image: url("../images/phone-icon.svg");
}
#contacts .contact__list .mail::before {
  background-image: url("../images/mail-icon.svg");
}
#contacts .contact__list .facebook::before {
  background-image: url("../images/facebook-icon.svg");
}
#contacts .contact__item {
  color: var(--c-teal);
  font-family: var(--font-SourceSansPro-Semibold);
  font-size: 1.1666666667rem;
}
#contacts .contact__item::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  background-repeat: no-repeat;
  margin-right: 0.8em;
  background-size: contain;
}
#contacts .note {
  color: var(--c-teal);
  font-family: var(--font-SourceSansPro-Regular);
  display: flex;
}
#contacts .note b {
  font-family: var(--font-SourceSansPro-Semibold);
}
#contacts .note span {
  display: inline-block;
  width: 1.1666666667rem;
  height: 1.1666666667rem;
  background-repeat: no-repeat;
  margin-right: 0.8em;
  background-image: url("../images/truck-icon.svg");
  background-size: contain;
}
#contacts .note small {
  width: 100%;
}

#testimonials div {
  text-align: center;
  background-image: url("../images/testimonial-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: max(200px, 40vh);
}
#testimonials .slider.grid {
  display: grid;
  place-items: center;
  width: 100%;
}
#testimonials .slider.grid img {
  grid-column: 1/2;
  grid-row: 1/2;
}
@media screen and (max-width: 768px) {
  #testimonials .slider.grid img {
    width: 85%;
  }
}

.modal {
  max-width: 600px;
  padding: 1.5rem;
  border: 0;
  border-radius: 0.5rem;
}
.modal-image {
  position: relative;
  margin-bottom: 1rem;
}
.modal-image .controls {
  position: absolute;
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;
}
.modal-image .controls i {
  color: #fff;
}
.modal-image-text {
  position: absolute;
  bottom: 0;
  background: var(--c-turquoise);
  font-size: 0.875rem;
  color: #fff;
  font-family: var(--font-SourceSansPro-Semibold);
  padding: 0.775rem;
}
.modal::backdrop {
  background: #000;
  opacity: 0.5;
}