@use "sass:map";
// Define a map of breakpoints and their associated sizes
$breakpoints: (
    sm: 576px,
    md: 768px,
    lg: 1024px,
    // or 992px
    xl: 1200px,
    xxl: 1400px,
);
// Define a map of device types and their associated media queries
$devices: (
    "mobile": "(max-width: #{map.get($breakpoints, md) - 0.02})",
    "tablet": "(min-width: #{map.get($breakpoints, md)}) and (max-width: #{map.get($breakpoints, lg) - 0.02})",
    "desktop": "(min-width: #{map.get($breakpoints, lg)})",
);
// Define a mixin that takes a breakpoint/device type as a parameter and generates a media query
@mixin media-query($size, $range: null) {
    // Check if $range is not a valid value
    @if ($range != null) and ($range != "min") and ($range != "max") {
        //  if $range is not a valid value show error message
        @error "Invalid range value: #{$range}. Must be 'min', 'max', or null.";
        //  if $range is a valid value...
    } @else {
        $max-or-min: if($range == null or $range == "min", "min", if($range == "max", "max", $range));
        // Check if the given size is a breakpoint
        @if (map-has-key($breakpoints, $size)) {
            // If it is, get the associated size value
            $media-size: map-get($breakpoints, $size);
            // If a valid size value was found...
            @if $media-size {
                // ... generate a media query with the given size as the minimum width
                @media screen and (#{$max-or-min}-width: $media-size) {
                    @content; // ... and include any nested CSS content within the media query block
                }
                // If an invalid size value was found...
            } @else {
                @warn "No matching breakpoints found for #{$size}.";
            }
            // If the given size is not a breakpoint but is a device type...
        } @else if (map-has-key($devices, $size)) {
            // Get the associated media query string
            $media-size: map-get($devices, $size);
            // If a valid media query string was found...
            @if $media-size {
                // ... generate a media query using the query string
                @media screen and #{$media-size} {
                    @content; // ... and include any nested CSS content within the media query block
                }
                // If an invalid media query string was found...
            } @else {
                @warn "No matching device found for #{$appliance}.";
            }
        }
    }
}