//This file contains all the @font-face declarations used in the project
@use "sass:math";
@use "../abstracts/" as util;
@use "../abstracts/variables" as vars;

.title {
    font-size: util.convert-to-rem(57);
    font-family: util.font(Adelle-Bold);

    span {
        @include util.media-query(mobile) {
            display: block;
        }
    }

    small {
        font-size: util.convert-to-em(62, 69);
        font-family: util.font(Adelle-Regular);
    }
}

.heading {
    &--primary {
        font-size: util.convert-to-rem(39.7999999992);
        color: util.color(c-teal);
        font-family: util.font(Adelle-Bold);

        &>small {
            font-size: util.convert-to-em(32, 47);
            white-space: nowrap;
            font-family: util.font(Adelle-Regular);
        }

        &+.paragraph {
            margin-top: util.fs-size(25);
        }
    }

    &--secondary {
        font-size: util.fs-size(33);
        color: util.color(c-turquoise);
        font-family: util.font(Adelle-Regular);

        &+.subheading--secondary {
            margin-block-start: 1rem;
        }

        &+.form__image {
            margin-block-end: 1rem;
        }
        &+.paragraph {
            margin-top: 1em;
        }
    }
}

.subheading {
    &--primary {
        font-size: util.fs-size(32);
        color: util.color(c-apricot);
        font-family: util.font(Adelle-Bold);
    }

    &--secondary {
        font-size: util.fs-size(29);
        color: util.color(c-apricot);
        font-family: util.font(Adelle-Regular);
        text-decoration: underline;

        &~.c-price {
            font-size: util.fs-size(24);
        }

        &+.form {
            margin-top: 1rem;
        }
    }
}

.lead {
    span {
        &:first-child {
            color: util.color(c-turquoise);
            font-size: util.fs-size(28);
            font-family: util.font(SourceSansPro-Semibold);
        }

        &:last-child {
            color: util.color(c-grey);
            font-size: util.fs-size(20);
            font-family: util.font(SourceSansPro-Regular);
        }
    }
}

.paragraph {
    font-size: max(16px, util.convert-to-rem(18.6));
    line-height: max(16px, 1.4em);
    font-family: util.font(SourceSansPro-Regular);
    color: #8E8E8E;

    &+.subheading--primary {
        margin-top: util.fs-size(38);
    }

    &>p+p {
        margin-top: 1em
    }
}


.c {
    &-price {
        font-size: max(16.8px, util.convert-to-rem(19));
        color: util.color(c-teal);
        font-family: util.font(SourceSansPro-Semibold);

        &>small {
            font-size: util.fs-size(24, em, 28);
            font-family: util.font(SourceSansPro-Regular);
        }

        &+.button {
            margin-top: util.fs-size(38);
        }

        &+.subheading--secondary {
            margin-block-start: 1rem;
        }
    }
}

section.container {
    &>header {
        text-align: center;
    }
}

#visite_guides {
    background-color: util.color(c-cream);

    .heading--primary {
        margin-bottom: util.fs-size(21);
    }

    .lead {
        display: flex;
        flex-direction: column;
        row-gap: util.fs-size(14, em);
    }
}

.form {

    input:not([type="submit"]),
    textarea {
        background-color: util.color(c-cream);
        padding-inline: 0.6em;
        padding-block: 0.5em;
        font-family: util.font(SourceSansPro-Regular);
        width: 100%;
    }

    ::placeholder {
        color: #8E8E8E;
    }
}

#welcomeMessage {
    .heading--secondary {
        font-family: util.font(SourceSansPro-Semibold);
        text-align: center;
    }
    .paragraph > p {
        color: util.color(c-turquoise);
        text-align: center;
        max-inline-size: 45ch;
    }
}

.footer {
    display: flex;
    padding: 1.5rem;
    p {
        align-self: center;
        color: #fff;
        font-size: 0.675rem;
        font-family: util.font(SourceSansPro-Regular);
    }
}