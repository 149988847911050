@use "sass:math";
@use "../abstracts/" as util;
@use "../abstracts/variables" as vars;

.container {
    &--fluid {
        width: 100%;
    }

    padding-inline: 1.5rem;
    padding-top: util.fs-size(64);

    >header {
        margin-bottom: util.fs-size(53);
    }

    @include util.media-query(tablet) {
        padding-inline: util.fs-size(math.div(156, 2));
    }

    @include util.media-query(desktop) {
        padding-inline: 3.25rem;
    }
}

#header-carousel {
    display: grid;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    place-items: center;
    color: #fff;
    text-align: center;
    height: calc(100vh - util.util(navbar-height));

    @at-root {
        %span-through-grid {
            grid-column: 1/2;
            grid-row: 1/2;
        }
    }

    &>* {
        @extend %span-through-grid;
    }
    .title {
        height: fit-content;
    }

    &>.slider {
        position: relative;
        display: grid;
        z-index: -1;
        height: 100%;
        overflow: hidden;

        img {
            @extend %span-through-grid;
            opacity: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            
            $total-images: 3;
            $animation-duration: 10s;

            animation: fade $animation-duration infinite;

            @for $i from 2 through $total-images {
                &:nth-child(#{$i}) {
                    animation-delay: ($i - 1) * math.div($animation-duration, $total-images);
                }
            }
        }

        @keyframes fade {
            0% {
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            33% {
                opacity: 1;
            }

            53% {
                opacity: 0;
            }

            100% {
                opacity: 0;
            }
        }
    }

}

.grid {
    &-layout {
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr;

        @include util.media-query(tablet) {
            grid-template-columns: repeat(2, 1fr);

            > :last-child {
                grid-column: 1 / 3;
            }
        }

        @include util.media-query(desktop) {
            grid-template-columns: repeat(3, 1fr);

            > :last-child {
                grid-column: 1 / 4;
                display: grid;

                grid-template-columns: util.fs-size(100) repeat(3, auto);
                grid-auto-rows: repeat(5, auto);

                margin-bottom: util.fs-size(64);

                .grid-item {
                    grid-template-columns: 1fr;

                    &__images {
                        grid-column: 1/5;
                        grid-row: 1/6;
                        height: max(400px, 70vh);
                        overflow: hidden;
                    }

                    &__body {
                        grid-column: 2/3;
                        grid-row: 3/4;
                        z-index: 2;
                        background-color: #013c4fc7;
                    }
                }
            }
        }
    }

    &-item {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        isolation: isolate;

        @include util.media-query(md) {
            // grid-template-rows: repeat(2, auto) minmax(170px, 100%);
            grid-template-rows: repeat(2, auto) minmax(util.fs-size(170), 100%);
        }

        @at-root {
            %full-col-span {
                grid-column: 1/2;
            }
        }

        &>&__header {
            padding: 0 util.fs-size(18);
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) -100%, rgba(0, 0, 0, 0) 100%);
        }

        &__header {
            z-index: 2;
            grid-row: 2/3;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 1rem;
            color: #fff;
            @extend %full-col-span;

            h3 {
                font-size: util.fs-size(30);
                font-family: util.font(Adelle-Bold);
            }

            .controls {
                margin-bottom: util.fs-size(21);
            }
        }

        &__images {
            z-index: 1;
            grid-row: 1/3;
            @extend %full-col-span;
            display: grid;

            img {
                width: 100%;
                height: 100%;
                aspect-ratio: 1.5/1;
                object-fit: cover;
                grid-column: 1/2;
                grid-row: 1/2;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            background-color: util.color(c-teal);
            padding: util.fs-size(16) util.fs-size(18);
            @extend %full-col-span;

            p {
                font-size: max(16px, util.convert-to-rem(18));
                font-family: util.font(SourceSansPro-Regular);
                color: #fff;
                flex-grow: 2;
            }

            .button {
                align-self: flex-end;
            }
        }
    }
}

#transfere_aeroport_lile_maurice {
    margin-bottom: util.fs-size(64);

    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include util.media-query(desktop) {
        flex-direction: row;
    }

    .subheading--primary {
        text-decoration: underline;
    }

    .column {
        &:first-child {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include util.media-query(desktop) {
                width: 70%;
            }
        }

        &:last-child {
            @include util.media-query(desktop) {
                .heading--primary {
                    span {
                        display: block;
                    }
                }

                .paragraph {
                    max-width: 50ch;
                }
            }
        }
    }
}

#contacts {
    .flex-layout {
        display: flex;
        flex-direction: column;

        @include util.media-query(md) {
            flex-direction: row;
            align-items: center;
        }

        &__item {
            @include util.media-query(md) {
                width: 50%;
            }

            %color-turquoise {
                color: util.color(c-turquoise);
            }

            h3 {
                font-size: util.fs-size(30);
                font-family: util.font(Adelle-Bold);
                @extend %color-turquoise;
            }

            .lead {
                font-size: util.fs-size(28);
                font-family: util.font(SourceSansPro-Regular);
                @extend %color-turquoise;
            }

            &>fieldset {
                width: 100%;
                margin: auto;
                border: 2pt solid util.color(c-teal);
                padding-top: util.fs-size(45);
                padding-right: util.fs-size(40);
                padding-bottom: util.fs-size(40);
                padding-left: util.fs-size(40);

                @include util.media-query(lg) {
                    width: 80%;
                }

                legend {
                    padding: 0.6rem;

                    img {
                        display: inline-block;
                        width: 4rem;
                    }
                }
            }
        }
    }

    .contact {
        &__list {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            margin-top: 1rem;
            margin-bottom: 1rem;

            .phone {
                &::before {
                    background-image: url("../images/phone-icon.svg");
                }
            }

            .mail {
                &::before {
                    background-image: url("../images/mail-icon.svg");
                }
            }

            .facebook {
                &::before {
                    background-image: url("../images/facebook-icon.svg");
                }
            }
        }

        &__item {
            color: util.color(c-teal);
            font-family: util.font(SourceSansPro-Semibold);
            font-size: util.fs-size(28);

            &::before {
                content: "";
                display: inline-block;
                width: 1em;
                height: 1em;
                background-repeat: no-repeat;
                margin-right: 0.8em;
                background-size: contain;
            }
        }
    }

    .note {
        color: util.color(c-teal);
        font-family: util.font(SourceSansPro-Regular);
        display: flex;

        b {
            font-family: util.font(SourceSansPro-Semibold);
        }

        span {
            display: inline-block;
            width: util.fs-size(28);
            height: util.fs-size(28);
            background-repeat: no-repeat;
            margin-right: 0.8em;
            background-image: url("../images/truck-icon.svg");
            background-size: contain;
        }

        small {
            width: 100%;
        }
    }
}

#testimonials {
    div {
        text-align: center;
        background-image: url('../images/testimonial-bg.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: max(200px, 40vh);
    }

    .slider.grid {
        display: grid;
        place-items: center;
        width: 100%;

        img {
            grid-column: 1/2;
            grid-row: 1/2;

            @include util.media-query(md, max){
                width: 85%;
            }
        }
    }
}

.modal {
    max-width: 600px;
    padding: 1.5rem;
    border: 0;
    border-radius: 0.5rem;

    &-image {
        position: relative;
        margin-bottom: 1rem;

        .controls {
            position: absolute;
            width: 100%;
            height: 80%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-inline: 1rem;

            i {
                color: #fff;
            }
        }

        &-text {
            position: absolute;
            bottom: 0;
            background: util.color(c-turquoise);
            font-size: 0.875rem;
            color: #fff;
            font-family: util.font(SourceSansPro-Semibold);
            padding: 0.775rem;
        }
    }

    &::backdrop {
        background: #000;
        opacity: 0.5;
    }
}