@use '../abstracts/' as util;

.navbar {
    padding: 1rem 2rem;
    background: util.color(c-cream);
    display: flex;
    align-items: center;

    @at-root {
        %align-items {
            align-items: center;
        }
    }

    &__container {
        width: 100%;
        display: grid;
        @extend %align-items;

        @include util.media-query(lg, max) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include util.media-query(desktop) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__brand {
        width: util.clamp-calc(120, 160, 1px);
        display: inline-flex;

        &>img {
            min-inline-size: 1em;
            min-block-size: 0.33em;
        }
    }

    &__menu {
        color: util.color(c-teal);
        display: flex;
        @extend %align-items;

        &:first-of-type {
            justify-content: center;


            @include util.media-query(lg, max) {
                flex-direction: column;
                position: fixed;
                top: 0;
                right: -100%;
                height: 100vh;
                min-width: 290px;
                background-color: util.color(c-cream);
                @include util.transition(right 600ms ease);

                &.active {
                    right: 0;
                }

                li {
                    @include util.transition(color 600ms ease-in-out);

                    &:hover {
                        color: util.color(c-turquoise);
                    }

                    &+li {
                        margin-block-start: 1rem;
                    }
                }
            }

            @include util.media-query(desktop) {
                li {
                    span {
                        display: block;
                    }

                    +li {
                        margin-inline-start: 1.25rem;
                    }
                }
            }

        }

        &:last-of-type {
            color: util.color(c-turquoise);
            justify-content: end;
            gap: 0.525rem;

            @include util.media-query(lg, max) {
                position: absolute;
                right: 0;
                padding-inline-end: 2rem;
            }
        }

        li {
            color: inherit;
            font-family: util.font(SourceSansPro-Regular);
            text-align: center;
            min-inline-size: max-content;
            font-size: max(19px, util.convert-to-rem(20));
        }

        @at-root .navbar {
            &__language {
                margin-inline-start: 1rem;
                border-block-end: 1pt solid;
            }
        }
    }

    &__toggle {
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        font-size: 1.2rem;
        display: grid !important;
        place-content: center;

        @include util.media-query(desktop) {
            display: none !important;
        }
    }

}

.footer {
    min-height: 3rem;
    margin-block-start: 2.5rem;
    background-color: util.color(c-teal);
}