$vendor-prefixes: ("webkit", "moz", "ms", "o");

@mixin prefix($property, $value) {
    @each $prefix in $vendor-prefixes {
        -#{$prefix}-#{$property}: $value;
    }
    #{$property}: $value;
}

// Define mixins for each property
@mixin filter($params) {
    @include prefix("filter", $params);
}

@mixin transform($params) {
    @include prefix("transform", $params);
}

@mixin transform-origin($params) {
    @include prefix("transform-origin", $params);
}

@mixin transform-style($style: preserve-3d) {
    @include prefix("transform-style", $style);
}

@mixin transition($properties...) {
    @if length($properties) >=1 {
        @include prefix("transition", $properties);
    } @else {
        @include prefix("transition", "all 0.2s ease-in-out 0s");
    }
}

@mixin animation($str...) {
    @include prefix("animation", $str);
}
