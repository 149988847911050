//This is a SCSS file containing the reset/normalize code for CSS. It sets default values for all properties in order to standardize the rendering of elements across different browsers and devices. The code includes styles for various HTML elements, as well as placeholders for common form element styles. It also defines button types and states, and loops through each type and state to extend the appropriate placeholder selector. Using this file as a starting point can help ensure consistency and avoid browser-specific quirks when developing CSS styles for a website or application.

@use "sass:map";
@use "sass:math";
@use "../abstracts/variables" as vars;
@use "../abstracts/" as util;

@each $font-name, $font-types in vars.$fonts {
  @each $font-type in $font-types {
    $font: "#{$font-name}-#{$font-type}";
    @include util.font-face("#{$font}", "../fonts/#{$font}", normal, normal, woff2 woff);
  }
}

:root {
  @include util.variables-to-css-props(vars.$colors);
  @include util.variables-to-css-props(vars.$utilities);

  @each $font-name, $font-types in vars.$fonts {
    @each $font-type in $font-types {
      $font: "#{$font-name}-#{$font-type}";
      --font-#{$font}: #{$font}, Arial, Helvetica, sans-serif;
    }
  }
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  //Below a brief example of the font size
  //* breakpoint: (minimum_font_size, maximum_font_size, minimum_screen_width, maximum_screen_width, unit)
  // $font-sizes: (
  //   default: (16, 24, 0, 575.98, "px"),
  //   sm: (16.5, 26.5, 0, 767.98, "px"),
  //   tablet: (16, 26, 0, 1023.98, "px"),
  //   desktop: (12, 24, 0, 1399.98, "px")
  // );

  // @each $breakpoint, $values in $font-sizes {
  //   @include util.media-query(#{$breakpoint}) {
  //     font-size: util.flexsize(
  //       nth($values, 1),
  //       nth($values, 2),
  //       nth($values, 3),
  //       nth($values, 4),
  //       nth($values, 5)
  //     );
  //   }
  // }
  font-size: util.clamp-calc(16, 24);

  scroll-behavior: smooth;
}

body {
  margin: 0;

  display: grid;
  grid-template-rows: minmax(min-content, 15vh) repeat(2, auto);
}

//* The new CSS reset - version 1.8.4 (last updated 14.2.2023)
//* GitHub page: https://github.com/elad2412/the-new-css-reset

/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a,
button {
  cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol,
ul,
menu {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-inline-size: 100%;
  max-block-size: 100%;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input,
textarea {
  user-select: auto;
  -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}

/* preformatted text - use only for this feature */
:where(pre) {
  all: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}

/* remove default dot (•) sign */
::marker {
  content: initial;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable="false"])) {
  overflow-wrap: break-word;
  line-break: after-white-space;
  user-select: auto;
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
  -webkit-user-drag: element;
}

/* Revert Modal native behavior */
:where(dialog:modal) {
  all: revert;
}
