@use "sass:math";
@use "../abstracts/" as util;
@use "../abstracts/variables" as vars;


.button {
    display: inline-block;
    text-transform: uppercase;
    width: fit-content;
    font-family: util.font(Adelle-Regular);
    cursor: pointer;

    &--primary {
        font-size: max(16px, util.convert-to-rem(18.6));
        color: util.color(c-teal);
        background-color: util.color(c-apricot);
        padding: util.fs-size(17, em) util.fs-size(29, em);
    }

    &--secondary {
        border-radius: 0.5em;
        font-size: util.fs-size(16);
        color: util.color(c-teal);
        background-color: #fff;
        padding: util.fs-size(12, em) util.fs-size(11, em);
    }

    &--close {
        background-color: #8E8E8E;
        color: #fff;
        padding: 0.3em 0.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;

        &+.heading--secondary {
            padding-top: 1rem;
        }
    }
}

input.button--primary {
    background-color: util.color(c-turquoise);
    color: #fff;
    font-family: util.font(SourceSansPro-Semibold);
}

.controls>a {
    cursor: pointer;
}