$year: 2023;
$month: 7;
$day: 9;
/*
 * My Awesome Stylesheet
 *
 * Author: Cedric Chetty
 * Version: 1.0.0
 * Last updated: #{$day}.#{$month}.#{$year}
 *!
 * WARNING: Editing this CSS File
 *
 * This CSS file contains the layout and styling of the website. Modifying this file without proper understanding of CSS may have unintended consequences and potentially affect the entire website.
 * Please exercise caution when making changes to this file. It is recommended to have a good understanding of CSS and the impact of your modifications on the overall website layout and functionality.
 * If you are unsure about making changes to this file, please consult with a developer or someone with expertise in CSS to avoid any detrimental effects.
 * Any modifications made to this file should be thoroughly tested to ensure they do not introduce issues or break the website's appearance or functionality.
 * By continuing to edit this CSS file, you acknowledge the potential risks associated with making changes without proper CSS knowledge.
 */

