// A mixin to generate @font-face rules
//
// Arguments:
// $fontName: The name of the font family.
// $fontPath: The path to the font files.
// $fontWeight: The font weight. Optional.
// $fontStyle: The font style. Optional.
// $fontExtensions: The file extensions of the font files.
//
// Returns: @font-face rules.
@mixin font-face($fontName, $fontPath, $fontWeight: null, $fontStyle: null, $fontExtensions: eot woff2 woff ttf svg) {
    $fileExtensionModifiers: (
        eot: "?",
        svg: "#" + str-replace($fontName, " ", "_")
    );

    $fontFormats: (
        otf: "opentype",
        ttf: "truetype"
    );

    $src: null;

    // Loop through each file extension and create a source URL for it
    @each $extension in $fontExtensions {
        $extensionModifier: if(map-has-key($fileExtensionModifiers, $extension), $extension + map-get($fileExtensionModifiers, $extension), $extension);
        $format: if(map-has-key($fontFormats, $extension), map-get($fontFormats, $extension), $extension);
        $src: append($src, url(quote($fontPath + "." + $extensionModifier)) format(quote($format)), comma);
    }

    // Output the @font-face rules
    @font-face {
        font-family: quote($fontName);
        font-style: $fontStyle;
        font-weight: $fontWeight;
        src: $src;
    }
}
